<template>
    <div>
        <Pop :clickClose="true" ref="pops">
            <div slot="popContent">
                <div class="btnGroup">
                    <div class="BtnGroupList">
                        <Btn :btnClass="index?'btnGroupBtn btnBt1':'btnGroupBtn'"
                             v-for="(btn,index) in btnList"
                             :key="index" @callBack="btn.callback">
                            <span slot="btnContent">{{btn.txt}}</span>
                        </Btn>
                    </div>

                    <Btn btnClass="btnGroupBtn closeBtnGroup" @callBack="openPop">
                        <span slot="btnContent">取消</span>
                    </Btn>
                </div>
            </div>
        </Pop>
    </div>
</template>

<script>
    export default {
        name: "BtnGroup",
        props: ['btnList'],
        methods: {
            openPop(type) {
                this.$refs.pops.changePop();
            }
        }
    }
</script>

<style>
    .btnGroup {
        padding: 0 .2rem .3rem .2rem;
    }

    .btnGroupBtn {
        background: #fff !important;
        color: #13161B !important;
        font-size: .15rem;
        height: .35rem;
        line-height: .35rem;
        padding: .05rem 0;
    }

    .closeBtnGroup {
        margin-top: .2rem;
        border-radius: .05rem;
    }

    .btnBt1 {
        border-top: 1px solid #F5F5F5;
    }

    .BtnGroupList {
        border-radius: .05rem;
        overflow: hidden;
    }
</style>