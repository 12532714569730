<template>
    <div class="orderSettle">
        <Header backType="blank" :back="true" :backEvent="true" @backEvent="back"></Header>
        <div class="orderSettle-container">
            <div class="publicHead">
                <p>
                    定额发票
                    <Svgs
                        @click.native="openPop"
                        color="#FECE30"
                        classes="invoiceTips"
                        name="iconiconfontquestion"
                    ></Svgs>
                </p>
            </div>
            <div class="normalInputRow">
                <MInput title="发票代码" type='tel' pla="请输入发票代码" v-model="params.invoiceCode"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="发票号码" type='tel' pla="请输入发票号码" v-model="params.invoiceNum"></MInput>
            </div>

            <div class="paymentInvoice">
                <viewer :images="invoiceFilesList" class="imgContainer">
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <img :src="list.url" alt/>
                            <Svgs
                                color='#FF484D'
                                classes="paymentDelete"
                                name="iconshanchu1"
                                @click.native="deleteInvoice(list)"
                            ></Svgs>
                        </div>
                    </template>
                </viewer>
            </div>
        </div>
        <div class="paymentBtnRow2">
            <Btn btnClass="paymentUpload" @callBack="openBtnGroup()">
                <span slot="btnContent">
                    <Svgs color="#ffffff" classes="paymentUploadImg" name="icontupian"></Svgs>上传发票
                </span>
            </Btn>

            <Btn
                btnClass="paymentSubmit"
                @callBack="submitInvoice"
            >
                <span slot="btnContent">提交</span>
            </Btn>
        </div>

        <Pop ref="pop" :clickClose="true">
            <div slot="popContent">
                <Tips />
            </div>
        </Pop>

        <MessageBox ref="remove" type="tips" messageTxt="确定删除图片？" @onOk="remove"></MessageBox>
        <BtnGroup ref="btnGroup" :btnList="btnList"></BtnGroup>
    </div>
</template>

<script>
import Tips from "./Tips";
import BtnGroup from "./BtnGroup";
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import { uploadQuotaInvocie } from "@/config/uploadInvocie.js";
import { UploadPlug } from "smart-filer";
import { indicator } from 'smart-indicator'
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "UploadQuotaInvocie",
    components: {
        Tips,
        BtnGroup
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    data() {
        return {
            params: {
                itemId: '',
                invoiceCode: '', //发票代码
                invoiceNum: '', //发票号码
                invoiceType: '增值税定额',
                fileIds: [] //发票fileKey
            },  
            uploadBtnList: [
                {
                    txt: "拍照",
                    callback: this.graph
                }
            ],
            btnList: [],
            list: {},
            invoiceFilesList: [],
            first: false
        };
    },
    mounted() {},
    methods: {
        addfileIds() {
            let arr = []
            this.invoiceFilesList.map(item => {
                arr.push(item.fileKey)
            })
            this.params.fileIds = arr
        }, 
        async openBtnGroup() {
            if(window.flutter_inappwebview){
                // 获取权限
                let power = await window.flutter_inappwebview.callHandler("Permission.checkAndRequestCamera",'');
                if(!power) return
                let uploadFileData = {
                    "uploadUrl": process.env.VUE_APP_GATEWAY + '/foundation/aliyun/api/oss/upload', //图片上传的oss地址
                    "hideCamera": false,
                    "hideGallery": true,
                    "hidePdfAndOfd": true
                }
                let result = await window.flutter_inappwebview.callHandler("App.openImagePicker",JSON.stringify(uploadFileData));
                this.invoiceFilesList.push(result[0].content)
                if(this.first) {
                    setTimeout(() => {
                        document.querySelector(".orderSettle-container").scrollTo({
                            top: 100000, 
                            behavior: "smooth"
                        })
                    }, 200)
                }
                this.first = true
                this.addfileIds()
                this.mappingAsync(result)
                return
            }
            this.btnList = this.uploadBtnList;
            this.$refs.btnGroup.openPop();
        },
        //拍照
        graph() {
            this.$refs.btnGroup.openPop();
            let _this = this
            uploadInstance.cameraFile({
                callback: data => {
                    indicator.open()
                    uploadInstance.addAsync({
                        fileList: data,
                        bucketAlias: "SmartEvent",
                        callback: (result) => {
                            indicator.close()
                            _this.invoiceFilesList.push(result[0].content)
                            if(_this.first) {
                                setTimeout(() => {
                                    document.querySelector(".orderSettle-container").scrollTo({
                                        top: 100000, 
                                        behavior: "smooth"
                                    })
                                }, 200)
                            }
                            _this.first = true
                            _this.addfileIds()
                            _this.mappingAsync(result)
                        }
                    })
                }
            })
        },
        mappingAsync(result) {
            uploadInstance.mappingAsync({
                fileKeys: [result[0].content.fileKey],
                mappingData: {
                    processId: this.orderDetail.proposalId,
                    itemId: this.orderDetail.itemId,
                    relatedType: "Invoice",
                    catalog: "发票"
                },
                callback: () => {}
            })
        },
        deleteInvoice(list) {
            this.list = list
            this.$refs.remove.openPop();
        },
        remove() {
            this.invoiceFilesList.forEach((item, index) => {
                if(this.list.fileKey == item.fileKey) {
                    this.invoiceFilesList.splice(index, 1)
                }
            })
            this.addfileIds()
        },
        // 提交发票
        submitInvoice() {
            let validateParams = this.params
            validateParams.hasFiles = this.invoiceFilesList.length ? true : false 
            if(!this.validate(validateParams, uploadQuotaInvocie))return;  

            this.params.itemId = this.orderDetail.itemId;    
            miceService.saveInvoice(this.params).then(res => {
                if(res && res.success) {
                    this.back()
                }
            })
        },
        openPop() {
            this.$refs.pop.changePop();
        },
        back() {
            const query = this.$route.query
            this.$router.push({ path: '/uploadInvoice', query: {
                invoiceType: query.invoiceType,
                invoiceCount: query.invoiceCount                
            }})
        }
    }
};
</script>

<style>
@import "../../views/OrderSettle/OrderSettle.scss";

.invoiceTips {
    position: absolute;
    left: .9rem;
    top: .04rem;
}

.invoiceSubmit {
    margin-top: 0.1rem !important;
}
</style>